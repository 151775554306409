// extracted by mini-css-extract-plugin
export var anchor = "forms-module--anchor--ac567";
export var deliveryMethod = "forms-module--deliveryMethod--7a379";
export var divDeliveryInfo = "forms-module--divDeliveryInfo--bf801";
export var form = "forms-module--form--604cc";
export var formShortened = "forms-module--formShortened--cf4ee";
export var header = "forms-module--header--814f8";
export var icon = "forms-module--icon--db154";
export var iconbutton = "forms-module--iconbutton--629d5";
export var imagebutton = "forms-module--imagebutton--d581c";
export var italic = "forms-module--italic--5a6db";
export var mainButton = "forms-module--mainButton--15c37";
export var msg = "forms-module--msg--6a834";
export var pickUpLink = "forms-module--pickUpLink--cb50e";
export var text = "forms-module--text--237fb";
export var textbutton = "forms-module--textbutton--7c94a";