import React from "react"
import { navigate } from 'gatsby'
import {
	Button,
	Form,
	Message,
	Header
} from 'semantic-ui-react'
import * as styles from './styles/forms.module.css'
import {
	confirmationColor,
	backColor
} from '../../globalVar'

const Negosyo = require('../../../lib/negosyo')


class SignInOTPChangePassword extends React.Component {
	state = {
		password: this.props.password,
		contact: this.props.contact,
		auth_code:'',
		stage: 0,
		error: '',
		success: '',
		loading:false,
		loadingResend:false,
		timeOut1:undefined,

		//countdown:0,//countdown for resend code
		errorMessage: {
			contact: undefined,
			auth_code: undefined,
		}
	}
  componentWillUnmount() {
		clearTimeout(this.state.timeOut1)
	}

	componentDidMount() {
		const {
			resendCode,
			contact
		} = this.props

		if(contact !== undefined) {
			if( resendCode ) {

				Negosyo.getUser(contact)
				.then((user)=>{
					this.setState({loadingResend:true})
					Negosyo.resendNegosyoCode(contact, user.username)
					.then((data)=>{
						if(data.status==="OK") {
							this.setState({loadingResend:false})
							clearTimeout(this.state.timeOut1)
							const timeOut1 = setTimeout( ()=>{
								this.setState({success:''})
							}, 5000)
							this.setState({success:"Authorization code sent", timeOut1:timeOut1})
						}else if(data.status==="Error") {
							this.setState({loadingResend:false, error:"Unable to send authorization code"})
						}else {
							this.setState({
								loadingResend:false,
								error:"Unable to send authorization code"
							})
						}

					}).catch((e)=>{
						this.setState({
							loadingResend:false,
							error:"Unable to send authorization code"
						})
					})
				})
				.catch(e =>{
					this.setState({error:"Unable to send authorization code"})
				})
			}else {
				//no resend when component mounts
			}
		}
	}

	handleInputChange = (e, {name, value}) => {
		this.setState({ [name]: value })
		if( value==='' ) {
			const message = " must not be blank"
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					[name] : message
				}
			}))
		} else {
			const message = undefined
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					[name] : message
				}
			}))
		}
	}

	//check if password is within min length and matches confirm Password
	validateInput = (states) => {
		// Copied from CartSummary
		for( let key2 of Object.keys(states) ) {
			this.setState({
				[key2]:this.state[key2].trim()
			})
			states[key2] = states[key2].trim()
		}

		let check = true;
		//needs to check for undefined since for some reason the forms start as undefined value
		for( let key of Object.keys(states) ) {
			if(states[key]==='' || states[key]===undefined ) {
				this.setState( prevState=>({
					errorMessage: {
						...prevState.errorMessage,
						[key]:"this must not be blank"
					}

				}))
				check = false
			}else if( key==="contact" ) {
				/*Update:not sure why the mobile pattern test was disabled though the pages that
				 *uses this component all check for the mobile pattern before showing this so it
				 *should be fine even if it is disabled
				 */
				//const pattern = /^(0|\+63)(8|9)([0-9]{9})$/g
				const result = true//pattern.test(states[key])
				if(!result) {
					check = false
					this.setState(prevState => ({
						errorMessage: {
							...prevState.errorMessage,
							[key] : "must be a valid mobile number"
						}
					}))
				}
			}
		}
		return check;
	}

	resendCode = () => {
		const {
			contact
		} = this.state
		this.setState({loadingResend:true})
		const check = this.validateInput({contact})
		if( check ) {
			Negosyo.getUser(contact)
			.then((user)=>{
				Negosyo.resendNegosyoCode(contact, user.username)
				.then((data)=>{
					if(data.status==="OK") {
						clearTimeout(this.state.timeOut1)
						const timeOut1 = setTimeout( ()=>{
							this.setState({success:''})
						}, 5000)
						this.setState({success:"Authorization code sent", timeOut1:timeOut1, loadingResend:false})
						//this.setState({countdown:60})
					}else if(data.status==="Error") {
						this.setState({loadingResend:false, error:"Unable to send authorization code"})
					}else {
						this.setState({loadingResend:false, error:"Unable to send authorization code"})
					}
				})
				.catch(e =>{
					this.setState({error:"Unable to send authorization code"})
				})
			}).catch((err)=>{
				this.setState({
					loadingResend:false,
					error:"Unable to send authorization code"
				})
			})
		}
	}



	confirmSignUp = () => {
		const {
			contact,
			auth_code
		} = this.state

		if(this.validateInput({contact, auth_code})) {
			this.setState({loading:true})
			Negosyo.getUser(contact)
			.then((data)=>{
				Negosyo.loginOTP(data.username, `${auth_code}_${contact}`)
				.then(()=>{
					this.setState({
						loading:false,
					})
					const {
						location
					} = this.props
					//custom key is for when the navigate's target url has a backButton so the new page inherits the location.key for the site mobileHeader backButton
					navigate(
						`/changePassword/`,
						{
							state:{
								location:location.pathname,
								authorized:true,
								customKey:location.key
							},
							replace:true
						}
					)
				}).catch(e =>{
					let message = "Unable To process authorization code"
					if(e==="Incorrect OTP") {
						message="Incorrect Authorization Code"
					}
					this.setState({
						error:message,
						loading:false
					})
				})

			}).catch((e)=>{
				this.setState({
					loading:false,
					error:"Unable to process authorization code, Please try again."
				})
			})
		}

	}

	render() {

		const {
			handleInputChange,
			confirmSignUp,
			resendCode
		} = this
		const {
			contact,
			auth_code,
			errorMessage,
			//countdown,
			loading,
			loadingResend
		} = this.state
		const {
			header
		} = this.props
		return (
			<React.Fragment>
				<Header className={styles.header}>{header}</Header>

				<Form onSubmit={confirmSignUp}>
					<Form.Input
							label ="Mobile #"
							placeholder='Mobile #'
							value={contact}
							error={errorMessage.contact}
							name='contact'
							disabled={this.props.contact!==undefined}
							onChange={handleInputChange}
							className={`${styles.form} ${styles.formShortened}`}
						/>

					<Form.Input
						label="Authorization Code"
						placeholder="Authorization Code"
						onChange={handleInputChange}
						error={errorMessage.auth_code}
						value={auth_code}
						name='auth_code'
						action={this.props.resendCode?{
							content: 'Resend Code',
							onClick: resendCode,
							type:"button",
							loading:loadingResend,
							disabled:loadingResend
						}:null}
						disabled={loading}
						className={`${styles.form} ${styles.formShortened}`}
					/>
					{this.state.error &&
						<Message negative className={styles.msg}>
							<Message.Header>{this.state.error}</Message.Header>
						</Message>
					}
					{this.state.success &&
						<Message positive className={styles.msg}>
							{this.state.success}
						</Message>
					}
					{this.props.backFunc && (
						<Button
							color={backColor}
							content={this.props.backMessage ? this.props.backMessage:"Back"}
							onClick={this.props.backFunc}
							className={this.props.backStyle ? this.props.backStyle:styles.form}
							disabled={this.props.backDisabled}
							type="button"
						/>
					)}

					<Button
						color={confirmationColor}
						loading={loading}
						disabled={loading}
						className={this.props.backFunc===undefined ? styles.form:styles.mainButton}
					>
						Proceed
					</Button>
				</Form>
				<br/>
			</React.Fragment>
		)
	}
}


export default SignInOTPChangePassword
